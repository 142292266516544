<template>
	<van-overlay :show="myVisible" z-index="1000" >
		<div class="wrapper" @click.stop>
			<div class="block flex column">
				<p class="b fs-17 text-center">小助手已离线，请先登录</p>
				<button v-bury="'chongxindenglu'" class="mt-5 button restart-button fs-14 b" @click="handleRestart">好的
				</button>
			</div>
		</div>
	</van-overlay>
</template>

<script>
export default {
	name: 'TheRestartOverlay',
	props: {
		modelValue: {
			type: Boolean,
			default: false
		}
	},
	emits: ['update:modelValue'],
	computed: {
		myVisible: {
			set(val) {
				this.$emit('update:modelValue', val)
			},
			get() {
				return this.modelValue
			}
		}
	},
	methods: {
		handleRestart() {
			this.myVisible = false
			this.$router.push('/login/login')
		}
	}

}
</script>

<style lang="scss" scoped>
.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.block {
	padding: 24px;
	background: #FFFFFF;
	border-radius: 16px 16px 16px 16px;

	.restart-button {
		width: 272px;
		height: 40px;
		background: #FF445E;
		border-radius: 99px 99px 99px 99px;
		color: rgba(255, 255, 255, .8);
	}
}

</style>
