<template>
  <div class="manger">

    <card-group>
      <card title="自动跟发官方朋友圈" :fillet="false" card-bottom>
        <template #header-right>
          <van-switch :modelValue="isOpenPush" size="20px" @change="statusChange"/>
        </template>
        <div class="fs-12 color-gray mt-1">自动替换朋友圈的链接为店主链接</div>
        <div class="fs-12 color-gray ">新绑定的机器人，在登陆24小时内朋友圈可能发送不成功。</div>
      </card>
      <card :fillet="false" is-link link-color="#999999" @click="$router.push('/friendCirclePermission')">
        <template #title>
          <span :class="permissionData.color"> {{ permissionData.title }}</span>
        </template>
        <template #header-right>
					<span class="mr-1  fs-12 text-flow tag-show"
                :class="permissionData.color ? permissionData.color : 'color-gray'">
						{{ permissionData.tag }}
					</span>
        </template>
        <div class="fs-12 color-gray mt-1">通过自动跟发的朋友圈，可设置可见人群</div>
      </card>
    </card-group>
    <card title="今日朋友圈推品" class="mt-3" is-link link-color="#999999">
      <template #header-right>
        <span class="mr-1 color-gray fs-12" @click="handleTargetYesterday">昨日推品（{{
            yesterdayPushedList.length
          }}）</span>
      </template>
      <van-list v-if="pushGoodsList.length >0 || pageLoading"
                v-model:loading="pageLoading"
                :finished="finished"
                finished-text="没有更多了">
        <div v-for="(item, index) in pushGoodsList"
             :key="item.msgId"
             class="item">
          <img class="headImg" :src="robotInfo.headImgUrl" alt=""/>
          <div class="text">
            <div class="name">
              <p>{{ robotInfo.nickName }}</p>
              <div class="flex" v-if="item.state === 0">
								<span class="follow-status color-gray" :class="item.isPush ? 'color-primary' : 'color-gray'">
									 {{ item.isPush ? '跟发中' : '未跟发' }}
								</span>
                <van-switch
                    class="ml-1"
                    :modelValue="item.isPush"
                    size="18px"
                    @change="setWaitStatus($event, item)"/>
              </div>
            </div>
            <friend-info :item="item" />
<!--            <wx-comment :name="robotInfo.nickName" v-if="item.comment" :comment="item.comment"/>-->
            <div class="time">
              <!--非未发送和队列中-->
              <p :class="![0, 1].includes(item.state) ? item.stateColor : ''" class="push-time fs-12">
                {{ [0, 1].includes(item.state) ? '预计发送：' : item.stateName }}
                <!--非发送中就展示发送时间-->
                <template v-if="![2].includes(item.state)">{{ item.createTime }}</template>
              </p>
              <span class="group-editor mr-2 fs-12" @click="goEdit(item)" v-if="item.state === 0 ">编辑</span>
              <!--非队列中 发送中 发送成功 -->
              <template v-else-if="![1,2,3].includes(item.state)">
										<span class="mr-2 fs-12"
                          :class="item.retryCount === 0 ? 'color-primary' : 'color-black'"
                          @click="item.retryCount === 0 ? handleResend(item) : ''">
											{{ item.retryCount === 0 ? (item.state === 4 ? '立即发送' : '重新发送') : '已重新发送' }}
										</span>
              </template>
            </div>
          </div>
        </div>
      </van-list>
      <no-data v-else :showButton="false" height="2.1rem"
               title="暂未发布朋友圈"/>
    </card>
  </div>
  <Teleport to=".page">
    <RestartOverlay v-model="restartShow"/>
  </Teleport>

</template>

<script>
export default {
  name: 'TheFriendCircle'
}
</script>
<script setup>
import Card from '../../components/Card'
import CardGroup from '../../components/CardGroup'
import WxComment from '../../components/WxComment'
import {computed, onMounted, ref} from 'vue'
import {Toast, ImagePreview, Notify} from 'vant'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import AplusRecord from '../../utils/AplusRecord'
import {
  ApiFriendCircleRetrySend,
  ApiGetFriendCircleSend,
  ApiGetPushMsg, ApiGetPushMsgRecord,
  ApiIsOpenPush,
  ApiSetNotPushMsg,
  ApiSetOpenPush
} from '../../api/FriendCircle'
import FriendInfo from '../../components/FriendInfo.vue'
import RestartOverlay from './TheRestartOverlay'

const store = useStore()
const router = useRouter()
// 是否推送
const isOpenPush = ref(false)
const permissionData = ref({})
// 页面加载
const pageLoading = ref(true)
// 页面加载
const finished = ref(false)
// 今日推送商品数据
const pushGoodsList = ref([])
// 昨日推品数据
const yesterdayPushedList = ref([])
const robotInfo = computed(() => {
  return store.getters.robotInfo
})
const restartShow = ref(false)

// 已经初始化
const isInit = ref(false)

const handleInit = async () => {
  isInit.value = true
  await GetUserFollowSet()
  await getFriendCirclePermissions()
  // 获取列表数据
  await getPushDataList()
  // 获取列表数据
  await getPushDataList('yesterday')
  pageLoading.value = false
  finished.value = true
}
// 重新激活的只需要获取未发送的以及朋友圈权限
const handleActivated = async () => {
  pageLoading.value = true
  finished.value = false
  await getFriendCirclePermissions()
  await getPushDataList()
  pageLoading.value = false
  finished.value = true
}

defineExpose({
  handleInit,
  handleActivated,
  isInit
})
const getFormatTime = (date) => {
  let formatDate = date.replace(/-/g, '/')
  return new Date(formatDate).getTime()
}

const showImage = (images, index) => {
  ImagePreview({
    images: images, // 预览图片的那个数组
    showIndex: true,
    loop: false,
    startPosition: index // 指明预览第几张图
  })
}

// 获取发送数据
const getPushDataList = async (type) => {
  try {
    let _Date = type === 'yesterday' ?
        new Date(Date.now() - 24 * 3600 * 1000)
        : new Date()
    _Date = _Date.toLocaleDateString()
        .replace(/\//g, '-')
    const res = await ApiGetPushMsg({
      startDate: `${_Date} 00:00:00`,
      endDate: `${_Date} 23:59:59`
    })
    const stateType = {
      2: {
        name: '发送中',
        color: 'color-blue'
      },
      3: {
        name: '发送成功：',
        color: 'color-success'
      },
      4: {
        name: '未发送:',
        color: ''
      }
    }
    let formatPushList = res ? res.map(i => {
      i.stateName = stateType[i.state] ? stateType[i.state].name : '发送失败：'
      i.stateColor = stateType[i.state] ? stateType[i.state].color : 'color-danger'
      i.control = true
      i.picUrl = i.picUrl ? i.picUrl.split(',') : []
      return i
    }) : []
    if (type === 'yesterday') {
      store.commit('resetYesterdayPushedList')
      store.commit('setYesterdayPushedList', formatPushList)
      yesterdayPushedList.value = formatPushList
    } else {
      pushGoodsList.value = formatPushList
    }
  } catch (e) {
    console.log(e)
  }
}

// 获取跟发状态
const GetUserFollowSet = async () => {
  try {
    const data = await ApiIsOpenPush()
    isOpenPush.value = data
  } catch (e) {
    console.log(e)
  }
}

// 获取朋友圈权限
const getFriendCirclePermissions = async () => {
  try {
    const res = await ApiGetFriendCircleSend(robotInfo.value.robotWxId)
    const tag = res.tagList.map(x => x.tagName)
        .join()
    const typeData = {
      0: {
        title: '谁可以看',
        color: '',
        tag: '所有人可见'
      },
      1: {
        title: '谁不可看',
        color: 'color-primary',
        tag: tag
      },
      2: {
        title: '谁可以看',
        color: 'color-success',
        tag: tag
      }
    }
    // 可见类型 0：默认无限制 1：指定不可见 2：指定可见
    permissionData.value = typeData[res.privateType]
  } catch (e) {
    console.warn(e)
  }
}
// 判断登录状态 触发登录弹窗
const judgeOnlineState = () => {
  if (robotInfo.value.state === 0) {
    restartShow.value = true
    return true
  }
}
// 状态改变
const statusChange = async (val) => {
  if (judgeOnlineState()) return
  try {
    await ApiSetOpenPush(val)
    Toast('切换成功')
    isOpenPush.value = val
    AplusRecord('zidonggenquankaiguan', val)
  } catch (e) {
    console.log(e)
  }
}

// 编辑
const goEdit = (val) => {
  if (new Date(val.pushTime).getTime() < (Date.now() - 5 * 60 * 1000)) {
    Notify({
      type: 'warning',
      message: '朋友圈提前5分钟进行排队发送，不能编辑了哦！',
      duration: 1000
    })
    return
  }
  router.push({
    path: '/friendCircleEdit',
    query: {
      msgId: val.msgId
    }
  })
}
// 重新发送
const handleResend = async (val) => {
  if (judgeOnlineState()) return
  try {
    val.retryCount++
    Notify({
      type: 'success',
      message: '已发起重新发送请求！'
    })
    await ApiFriendCircleRetrySend(val.msgCode)
  } catch (e) {
    console.warn(e)
  }
}
// 设置跟发状态
const setWaitStatus = async (val, item) => {
  if (judgeOnlineState()) return
  try {
    await ApiSetNotPushMsg({
      msgId: item.msgId,
      msgCode: item.msgCode,
      isPush: val
    })
    item.isPush = val
    Toast('设置成功')
  } catch (e) {
    console.log(e)
  }
}

const handleTargetYesterday = () => {
  if (yesterdayPushedList.value.length === 0) {
    Toast({
      type: 'warning',
      message: '暂无昨日推品哦！'
    })
    return
  }
  router.push('/yesterdayPush')
}
</script>
<style lang="scss" scoped>
.tag-show {
  max-width: 200px;
}

.manger {
  padding: 12px 15px;
}

.friendCircle {
  :deep(.van-list) {
    height: 420px;
  }

  .show-all {
    -webkit-line-clamp: initial;
  }
}

.push-time {
  line-height: 12px;

}

.group-editor {
  color: #34557B;
  line-height: 12px;
}

.follow-status {
  font-size: 12px;
  line-height: 12px;
}

.item {
  padding: px(15) 0;
  border-bottom: px(1) #f1f1f1 dashed;
  font-size: px(14);
  display: flex;

  .headImg {
    width: px(44);
    height: px(44);
    overflow: hidden;
    border-radius: 50%;
    margin-right: px(6);

    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .text {
    flex: 1;

    .name {
      display: flex;
      margin-bottom: px(5);
      align-items: center;

      p {
        flex: 1;
        margin: 0;
        color: #35557c;
        font-size: px(14);
        line-height: px(20);
      }

      .btn {
        color: #35557c;
        font-size: px(12);
        margin-right: px(5);

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .con {
      margin-bottom: px(15);
    }

    .link {
      display: flex;
      align-items: center;
      background-color: #f6f6f6;
      border-radius: px(4);
      padding: px(7);
      margin-bottom: px(15);

      .linkImg {
        width: px(44);
        height: px(44);
        margin-right: px(5);
        border-radius: px(2);
        overflow: hidden;

        .van-image {
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      .linkTitle {
        flex: 1;
      }
    }

    .time {
      display: flex;
      align-items: center;

      p {
        font-size: px(12);
        flex: 1;
        margin: 0;
      }

      .btn {
        color: #35557c;
        font-size: px(12);
      }
    }
  }

  &:last-child {
    border-bottom: 0;
  }
}

.van-grid {
  margin: px(10) 0;
}


</style>
