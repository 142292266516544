<template>
	<div class="card-group">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'CardGroup'
}
</script>

<style scoped lang="scss">
.card-group{
	border-radius: px(12);
	overflow: hidden
}
</style>
