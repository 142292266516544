<template>
	<div>
		<div class="text   fs-12 flex" :class="[colorClass, { 'h-between' : isLink}]" :style="{textAlign: textAlign}"
		     @click="show=true">
			<slot>如何避免小助手经常掉线？</slot>
			<img src="../assets/new/icon-right.png" class="link-img " v-if="isLink"/>
		</div>
		<van-popup v-model:show="show"
		           style="width: 75%"
		           class="flex column pl-3 pr-3">
			<div class="fs-14 b mt-4 title">如何让机器人更加稳定，不容易掉线？</div>
			<p class="mt-2 fs-12 color-primary mb-1">由于微信风控限制的原因，可能会出现小助手经常掉线的情况，请注意合理使用小助手，以下为使用中的注意事项：</p>
			<div class="context">
				<p class="fs-12  color-gray">1.使用推品小助手时，请尽量保证一部手机使用一张电话卡，并且登录一个微信号，用此微信号绑定小助手，并且不要频繁更改微信密码；</p>
				<p class="fs-12 mt-2 color-gray">2. 推品小助手在新绑定的8天内极有可能会被微信强制下线，发现离线后请及时登录上，随着登录时间较久，慢慢会变得稳定。如果进行注销操作也会使周期重新计算；
				</p>
				<p class="fs-12 mt-2 color-gray">3.请不要经常更换微信登录设备（比如切换手机登录同一个微信号），也不要同时使用其他平板设备登录微信号，此操作可能会使小助手下线；</p>
				<p class="fs-12 mt-2 color-gray">4.在初始绑定的24小时内，尽量避免频繁操作，如需推送素材，请注意间隔时间最好在半小时以上；</p>
				<div class=" mt-2 color-gray flex small-skill">
					5.
					<div class="fs-12">
						<p class="fs-12">为避免封号，建议经常进行养号操作，以下为一些养号小技巧：</p>
						<p class="fs-12 mt-2 ">① 网络环境：最好使用手机流量或者稳定的WiFi使用小助手，不要频繁切换网络，也避免使用不同的WiFi</p>
						<p class="fs-12 mt-2 ">② 微信钱包：要记得绑定银行卡，可以经常使用此微信号在商店或者网上进行支付操作</p>
						<p class="fs-12 mt-2 ">③ 公众号：关注正能量的官方公众号，每天进行浏览以及点赞公众号文章</p>
						<p class="fs-12 mt-2 ">④ 日常行为：与好友聊天/语音/视频、群内发言、发生活向朋友圈、点赞评论好友朋友圈、收发红包等</p>
						<p class="fs-12 mt-2 ">⑤ 其他行为：使用小程序、玩游戏等</p>
					</div>
				</div>
			</div>

			<button class="mt-4 mb-4 reflesh b"
			        @click="show=false">我知道了
			</button>
		</van-popup>
	</div>
</template>

<script>
// 风险规避指南
export default {
	name: 'RiskAvoidanceGuide',
	props: {
		colorClass: {
			type: String,
			default: 'color-blue'
		},
		textAlign: {
			type: String,
			default: 'center'
		},
		// 是否是链接
		isLink: {
			type: Boolean,
			default: false
		}

	},
	data() {
		return {
			show: false
		}
	}
}
</script>

<style scoped lang="scss">
.title {
	align-self: flex-start;
}

.context {
	height: 45vh;
	overflow-y: auto;
}

.small-skill {
	justify-content: flex-start;
	align-items: baseline;
}

.reflesh {
	padding: px(6) px(35);
	font-size: px(14);
	font-weight: 500;
	color: #FF445E;
	border-radius: px(2);
	border: px(1) solid #FF445E;
}

.color-blue {
	color: #3784F8
}

.link-img {
	width: 12px;
	height: 12px;
}

</style>
