<template>
	<div class="page">
		<van-sticky>
			<risk-avoidance-guide v-bury="'00001'" class=" header-tip" text-align="left" isLink
			                      colorClass="color-primary">
				<p class="flex"><img src="../../assets/new/remindCir_fill.png" alt="" class="img-guide mr-1">
					如何避免小助手经常掉线？</p>
			</risk-avoidance-guide>
		</van-sticky>
		<header class="header">
			<InfoCell :avatar="robot.headImgUrl" avatar-height=".48rem"
			          cellBackgroundColor="transparent"
			          class="pb-5" contextJustifyContent="space-between">
				<template #context>
					<div>
						<div class="fs-18  text-flow max-170px">{{ robot.nickName ? robot.nickName : '未命名' }}</div>
						<div class="fs-12 color-gray__secondary mt-1 max-170px">所属ID:{{ userInfo.userCode }}</div>
					</div>
					<div>
						<button class="button questionAndAnswer-button fs-12" @click="targetQA">
							<img src="./assets/icon-light.png" class="questionAndAnswer-icon" alt="">教程
						</button>
						<van-popover v-model:show="showPopover" theme="dark" :actions="actions"
						             placement="bottom-end" @select="handleSelectPopover">
							<template #reference>
								<img src="../../assets/new/icon_set.png" class="icon-set pr-1" alt="设置">
							</template>
						</van-popover>
					</div>
				</template>
			</InfoCell>
		</header>
		<div class="tab">
			<img src="../../assets/focus-on-banner.png" class="focus-banner" @click="focusOnBgVisible = true"/>
			<van-tabs v-model:active="tabActive" sticky offset-top=".32rem" swipeable line-height=".04rem" line-width=".2rem"
			          @rendered="handleRendered" @change="handleTabChange">
				<van-tab name="group" index="1">
					<template #title>
						<div class="flex">
							<div class="tab-logo flex h-center " :class="tabActive === 'group' ? 'active-bg' : 'inactive-bg'">
								<img src="../../assets/new/wechart_fill.png" class="tab-logo_img" alt="群管理">
							</div>
							<span class="fs-17 b ">群管理</span>
						</div>
					</template>
					<group-manager ref="GroupManagerRef"/>
				</van-tab>
				<van-tab name="friend" index="2">
					<template #title>
						<div class="flex ">
							<div class="tab-logo flex h-center " :class="tabActive === 'friend' ? 'active-bg' : 'inactive-bg'">
								<img src="../../assets/new/wechart-circle.png" class="tab-logo_img" alt="朋友圈">
							</div>
							<span class="fs-17 b ">朋友圈</span>
						</div>
					</template>
					<friend-circle ref="FriendCircleRef"/>
				</van-tab>
			</van-tabs>
		</div>
		<div v-bury="'chongxindenglu'" class="online-tip" v-if="isLogout" @click="handleRestart">
			您的小助手已离线，立即登录
			<van-icon name="arrow"/>
		</div>
		<logoutDialog v-model="logoutDialogVisible" @cancel="logoutDialogVisible = false" @confirm="ensureLogout"/>
		<van-overlay :show="imgDialog"
		             :click="imgDialog = false">
			<div class="popupRemind"
			     style="background: unset">
				<img src="../../assets/img1.png"
				     class="remind_img"/>
				<div class="flex h-center mt-2"
				     @click="imgDialog=false">
					<img src="../../assets/icon_close.png"
					     class="icon_close"/>
				</div>
			</div>
		</van-overlay>
		<restart-overlay v-model="restartShow"/>
		<popup-remind v-model="focusOnBgVisible" :img-url="focusOnBg" img-width="3rem" img-height="5rem"/>
		<PopupRemind v-model="newYearNoticeVisible" :img-url="newYear" img-height="75vh" img-width="auto"
		             @close="confirmActive"/>

	</div>

</template>


<script setup>
// components start
import InfoCell from '../../components/InfoCell'
import RiskAvoidanceGuide from '../../components/RiskAvoidanceGuide'
import PopupRemind from '../../components/PopupRemind'
import LogoutDialog from '../../components/LogoutDialog'
import GroupManager from './TheGroupManager'
import FriendCircle from './TheFriendCircle'
import RestartOverlay from './TheRestartOverlay'
// components end
// img start
import iconClose from '../../assets/new/icon_close.png'
import focusOnBg from '../../assets/focus-on-bg.png'
import newYear from '../../assets/new-year.jpg'
// img end
import { computed, onActivated, onDeactivated, onMounted, ref } from 'vue'
import { ApiGetPorts, ApiWxRobotLogout } from '@/api/WxRobot'
import { useStore } from 'vuex'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { Notify, Toast } from 'vant'
import AplusRecord from '../../utils/AplusRecord.js'


const store = useStore()
const router = useRouter()

// 群管理引用
const GroupManagerRef = ref(null)
// 朋友圈引用
const FriendCircleRef = ref(null)
// 让组件自动设置触发 change 事件
const tabActive = ref('')
const showPopover = ref(false)
const actions = [
	{ text: '注销', icon: iconClose }
]


let logoutDialogVisible = ref(false)
const robot = ref({})
let imgDialog = ref(false)
// 已离线
const isLogout = ref(false)
// 重新登录弹窗
const restartShow = ref(false)

const userInfo = computed(() => {
	return store.getters.userInfo
})

// 新年弹窗
const newYearNoticeVisible = ref(false)

// 关注弹窗
const focusOnBgVisible = ref(false)
onBeforeRouteLeave((to, from) => {
	// 离线并且没有登录操作  不是跳转到QA 并且没有模拟登陆凭证
	if (isLogout.value && to.path.indexOf('login') === -1 && !isClickQA.value && !sessionStorage.getItem('yh_H5:isTestLogin')) {
		restartShow.value = true
		return false
	}
	// 重置
	isClickQA.value = false
})
// 当前点击教程
let isClickQA = ref(false)
const targetQA = () => {
	isClickQA.value = true
	router.push('/questionAndAnswer')
}
onMounted(() => {
	showImgDialog()
	if (getActiveFlag()) {
		newYearNoticeVisible.value = true
	}
})
onActivated(async () => {
	if (tabActive.value === 'group') {
		GroupManagerRef.value && await GroupManagerRef.value.getGroup()
	} else if (tabActive.value === 'friend') {
		FriendCircleRef.value && await FriendCircleRef.value.handleActivated()
	}
	// 防止因为同步触发上述条件
	await getData()

})
onDeactivated(() => {})

// 关注弹窗 start
const todayTime = computed(() => {
	return `${new Date().getMonth() + 1}_${new Date().getDate()}`
})
const getActiveFlag = () => {
	// 新年活动
	const endDate = new Date(2023, 0, 28).getTime()
	return localStorage.getItem('yh_new-year:isActive') !== todayTime.value && (Date.now() - endDate) < 0
}
// 活动确认
const confirmActive = () => {
	// 参数为 活动日期截至日期
	localStorage.setItem('yh_new-year:isActive', todayTime.value)
}
// 关注弹窗 end

const showImgDialog = () => {
	if (!localStorage.getItem('yh_h5:isShowImg')) {
		imgDialog.value = true
		localStorage.setItem('yh_h5:isShowImg', true)
	}
}
// 获取机器人数据
const getData = async () => {
	try {
		const res = await ApiGetPorts()
		robot.value = res ? res[0] : {}

		isLogout.value = robot.value.robotWxId && robot.value.state === 0
		await store.dispatch('setRobotInfo', robot.value)
		if (!robot.value.robotWxId) {
			await router.replace({
				path: '/login/index',
				query: {
					from: 'index'
				}
			})
		}
	} catch (e) {
		console.log(e)
	}
}

const handleRestart = () => {
	restartShow.value = false
	router.push('/login/login')
}
const ensureLogout = async () => {
	const toast1 = Toast.loading({
		message: '注销中...',
		forbidClick: true,
		loadingType: 'spinner',
		duration: 0
	})
	try {
		await ApiWxRobotLogout({
			robotWxId: robot.value.robotWxId,
			deviceId: robot.value.deviceId,
			wxType: 0,
			reason: [
				{
					reasonType: 1,
					reasonData: '默认'
				}
			]
		})
		Notify({
			type: 'success',
			message: '注销成功！'
		})
		await router.replace({
			path: '/login/index',
			query: {
				from: 'index'
			}
		})
	} catch (e) {
		console.warn(e)
	}
	toast1.clear()
	logoutDialogVisible.value = false
}
// 气泡选择
const handleSelectPopover = (val, index) => {
	logoutDialogVisible.value = true
	AplusRecord('jiqirenzhuxiao')
}

const handleRendered = (name, title) => {
	// 渲染朋友圈时调用朋友圈接口
	if (name === 'friend' && FriendCircleRef.value && !FriendCircleRef.value.isInit) {
		FriendCircleRef.value.handleInit()
	}
}
// 触发埋点
const handleTabChange = (name) => {
	if (name === 'group') {
		AplusRecord('qunguanli')
	} else {
		AplusRecord('pengyouquan')
	}
}
</script>
<style scoped lang="scss">
.color-gray__secondary {
	color: rgba(0, 0, 0, 0.4);
}

.max-170px {
	max-width: 170px;
}

.questionAndAnswer-button {
	margin-right: 12px;
	width: 66px;
	height: 24px;
	background: linear-gradient(270deg, #FF445E 0%, #FF1E1E 100%);
	border-radius: 12px 12px 12px 12px;
	opacity: 1;
	color: #FFFFFF;
	border: 1px solid #FFFFFF;
}

.questionAndAnswer-icon {
	width: 16px;
	height: 16px;
	margin-right: 2px;
}

.header-tip {
	height: 32px;
	width: 100%;
	line-height: 32px;
	background: rgba(255, 255, 255, 0.5);
	padding: 0 15px;
	box-sizing: border-box;

	.img-guide {
		width: 16px;
		height: 16px;
	}
}

:deep(.van-sticky--fixed) {
	.header-tip {
		background: rgba(237, 237, 237, 1);
	}
}

.header {
	height: 307px;
	//width: 375px;
	background-image: url(../../assets/new/index-bg.png);
	background-size: contain;
	background-repeat: no-repeat;


	.icon-set {
		width: 24px;
		height: 24px;
	}

	.robot-state {
		width: 40px;
		height: 16px;
		box-sizing: border-box;
	}

	.color-white__secondary {
		color: rgba(255, 255, 255, .8);
	}

	.operation-button {
		width: 60px;
		height: 24px;
		background: rgba(0, 0, 0, 0.2);
		border-radius: 12px;
		opacity: 0.7;
		color: #FFFFFF;
	}

	:deep(.van-popover__action) {
		width: 74px;
	}
}


.online-tip {
	position: fixed;
	bottom: 0;
	box-sizing: border-box;
	padding: 10px 15px;
	width: 100%;
	left: 0;
	right: 0;
	font-size: 14px;
	font-family: PingFang SC-Medium, PingFang SC;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 20px;
	background: #FF445E;
	border-radius: 4px 4px 4px 4px;
	z-index: 100;
}

.page {
	padding-bottom: 40px;

	.tab {
		margin-top: -220px;

		.focus-banner {
			position: relative;
			left: 50%;
			transform: translateX(-50%);
			height: 60px;
			width: 350px;
			object-fit: contain;
		}
	}

	:deep(.van-tabs) {
		//margin-top: -200px;
		margin-top: 24px;

		.van-tabs__wrap {
			margin-top: -10px;
			height: 42px;

			.van-tabs__nav {
				background: transparent;
			}
		}

		.van-sticky--fixed {
			.van-tabs__wrap {
				padding-top: 10px;
				background: rgba(237, 237, 237, 1);
			}
		}

		.tab-logo {
			width: 24px;
			height: 20px;
			line-height: 20px;
			margin-right: 2px;
			border-radius: 99px 99px 99px 99px;

			.tab-logo_img {
				width: 16px;
				height: 16px;
			}
		}

		.active-bg {
			background: #FF445E;
		}

		.inactive-bg {
			background: #BDBDBD;
		}

		.tab-icon {
			width: 24px;
			height: 24px;
		}
	}
}


@import "index";

.question_answer {
	width: px(82);
	height: px(26);
	align-self: flex-start;
	margin-right: px(-4);
}

.upgrade-notification {
	height: px(40);
	object-fit: contain;
}


.set-group_button {
	width: px(40);
	height: px(18);
	border-radius: px(12);
	border: 1px solid;
}

.follow-group {
	max-width: px(100)
}


</style>
<style lang="scss">
// 修改 popup
.van-popup {
	[data-popper-placement='bottom-end'] .van-popover__arrow {
		right: 20px;
	}

	.van-popover__action {
		width: 92px;
	}
}

.van-popup[data-popper-placement='bottom-end'] .van-popover__arrow {
	right: 5px;
}
</style>
