<template>
	<div class="manger">
		<Card title="自动播货">
			<template #header-right>
				<span class="fs-12 color-primary">超7万店主使用小助手，收益提升100%</span>
			</template>
			<div class="no-group flex column mt-8" v-if="groupList.length === 0" v-bury="'tianjiaqun'">
				<span class="fs-13 color-gray">绑定卖货群，海量优质素材自动跟发，轻松赚钱</span>
				<button class="button bind-button mt-5" @click="$router.push('/group/openGroup')">立即绑群</button>
			</div>
			<div class="have-group mt-5" v-else>
				<InfoCell v-for="i in groupList" :key="i.chatroomId" class="mb-3" :round="false" avatar-height=".44rem"
				          :avatar="i.chatroomImgUrl" cell-background-color="#F4F4F4"
				          :customer-style="{alignItems: 'flex-start'}" context-follow-direction="column"
				          contextAlignItems="flex-start">
					<template #context>
						<div class="flex flex-1" style="width: 100%">
							<p class="text-flow b fs-14 flex-1 mr-2 "
							   :class="i.state === -1 ? 'chatroom-name': 'max_chatroom-name'">
								{{ i.chatroomName }}</p>
							<div class="no-broadcast fs-12" v-if="i.state === -1">未播货</div>
							<p v-bury="'zidongbohuoshezhi'" class="flex flex-right" style="margin-top: .02rem"
							   @click="handleSetMaterial(i)">
								<span class="color-primary">设置&nbsp;</span>
								<img src="../../assets/new/icon-right.png" alt="" class="set-link mr-1">
							</p>
						</div>
						<div class="mt-1 flex color-gray fs-12">
							<span class="flex-shrink0">{{ i.type === 2 ? '跟发官方素材' : '跟发采集素材' }}：</span>
							<span class="follow-group text-flow">
								{{
									i.type === 2 ? i.followOfficialMaterialName : i.followCollectionObjName
								}}
								</span>
						</div>
					</template>
				</InfoCell>
				<p v-bury="'tianjiaqun'" v-if="groupList.length < 5" class="add-group color-primary pb-2"
				   @click="$router.push('/group/openGroup')">
					<van-icon name="plus" size=".16rem"/>&nbsp;添加群
				</p>
			</div>
		</Card>
		<Card title="推品小助手" class="mt-3">
			<div class="flex mt-4">
				<div v-bury="'ruqunhuanying'" class="flex column flex-1"
				     @click="$router.push('/welcomeWords')">
					<img src="../../assets/new/logo_welcome.png" class="icon_logo">
					<div class="fs-14 mt-1 b">入群欢迎语</div>
				</div>
				<div v-bury="'zidonghuifu'" class="flex column flex-1"
				     @click="$router.push('/keywords')">
					<img src="../../assets/new/logo_key.png" class="icon_logo">
					<div class="fs-14 mt-1  b">自动回复</div>
				</div>
			</div>

		</Card>
	</div>
</template>

<script>
export default {
	name: 'TheGroupManager'
}
</script>
<script setup>
import Card from '../../components/Card'
import InfoCell from '../../components/InfoCell'

import { ApiQueryFollowLiveChatrooms } from '../../api/FollowLive'
import { onActivated, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const router = useRouter()
const store = useStore()
// 群列表
const groupList = ref([])

onMounted(() => {
	getGroup()
})

const getGroup = async () => {
	try {
		const res = await ApiQueryFollowLiveChatrooms()
		groupList.value = res.map(i => {
			// 播货群名
			i.chatroomName = i.chatroomName ? i.chatroomName : '未命名'
			// 跟发官方素材名
			i.followOfficialMaterialName = ''
			// 跟发素材（采集对象）名
			i.followCollectionObjName = i.teacherChatroomId ?
					(i.teacherChatroomName ? i.teacherChatroomName : '未命名')
					: '暂未设置'
			// 有id 说明已设置
			if (i.officalChatroomSerialNo || i.officalId) {
				i.followOfficialMaterialName = i.officalChatroomShowName ?
						i.officalChatroomShowName :
						(i.officalChatroomName ? i.officalChatroomName : '未命名')
			} else {
				i.followMaterialName = '暂未设置'
			}
			return i
		})
	} catch (e) {
		console.log(e)
	}
}
defineExpose({
	getGroup
})

// 设置跟发素材
const handleSetMaterial = (val) => {
	// 重置托管群信息
	store.commit('resetHostingGroupInfo')
	// 设置当前托管群信息
	store.commit('setHostingGroupInfo', val)
	router.push({
		path: '/followMaterial',
	})

}
</script>
<style scoped lang="scss">
.manger {
	padding: 12px 15px;
}

.no-group {

}

.bind-button {
	width: 96px;
	height: 40px;
	background: #FF445E;
	border-radius: 99px 99px 99px 99px;
	box-sizing: border-box;
	font-weight: 500;
	font-size: 14px;
	color: #FFFFFF;
}

.icon_logo {
	width: 48px;
	height: 48px;
}

.set-link {
	margin-top: -2px;
	width: 12px;
	height: 12px;
}

.chatroom-name {
	max-width: 130px;
	line-height: 20px;
}

.max_chatroom-name {
	max-width: 170px;
}

.no-broadcast {
	max-width: 134px;
	text-align: center;
	width: 40px;
	height: 16px;
	background: #FF445E;
	border-radius: 4px 4px 4px 4px;
	color: rgba(255, 255, 255, .8);
}

.add-group {
	text-align: center;
}

.follow-group {
	max-width: px(140)
}
</style>
