<template>
	<van-overlay :show="myVisible" z-index="101"
	             @click="onClickImgHide">
		<div class="popupRemind"
		     style="background: unset">
			<img :src="imgUrl" :style="{'width': imgWidth, 'height': imgHeight}"/>
			<div class="flex h-center mt-2"
			     @click="onClickImgHide">
				<img src="../assets/icon_close.png" class="icon_close"/>
			</div>
		</div>

	</van-overlay>
</template>

<script>
export default {
	name: 'PopupRemind',
	props: {
		modelValue: {
			type: Boolean,
			default: false
		},
		imgUrl: {
			type: String,
			default: ''
		},
		// ios中图片父级或者本身必须设置宽高才会显示
		imgWidth: {
			type: String,
			default: ''
		},
		imgHeight: {
			type: String,
			default: '100%'
		}
	},
	emits: ['update:modelValue'],
	computed: {
		myVisible: {
			set(val) {
				this.$emit('update:modelValue', val)
			},
			get() {
				return this.modelValue
			}
		}
	},
	methods: {
		onClickImgHide() {
			this.myVisible = false
			this.$emit('close')
		}
	}
}
</script>

<style scoped lang="scss">
.popupRemind {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: px(4);
	background-color: #fff;
}


.icon_close {
	width: px(32);
	height: px(32);
}

</style>
